import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import locale from 'antd/locale/ko_KR'
import AOS from 'aos'
import "aos/dist/aos.css"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { Path } from './config/Path'
import { Contact } from './pages/contact/Contact'
import { Home } from './pages/home/Home'
import { Login } from './pages/login/Login'
import { PrivacyTerm } from './pages/term/PrivacyTerm'
import { Wanted } from './pages/wanted/Wanted'
import { WhoWeAre } from './pages/who/WhoWeAre'
import { Works } from './pages/work/Works'
import "./styles/font.scss"
import "./styles/index.scss"

AOS.init()

const App = () => {
    return (
        <ConfigProvider
            locale={locale}
            theme={{
                token: {
                    colorPrimary: '#F8B31A',
                    borderRadius: 4,

                    colorBgContainer: '#fdfeff',
                },
                components: {
                    Menu: {
                        horizontalLineHeight: '62px',
                        itemHoverBg: '#fff3d9',
                    }
                }
            }}
        >
            <BrowserRouter>
                <Routes>
                    <Route path={Path.who_we_are} element={<WhoWeAre />} />
                    <Route path={Path.works} element={<Works />} />
                    <Route path={Path.wanted} element={<Wanted />} />
                    <Route path={Path.contact} element={<Contact />} />

                    <Route path={Path.login} element={<Login />} />
                    <Route path={Path.privacy_term} element={<PrivacyTerm />} />

                    <Route path="*" element={<Home />} />
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    )
}

export default App