import { Button, Checkbox, Col, Divider, Flex, Form, Input, message, Modal, Row, Typography } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useState } from "react"
import { PrivacyTermDrawer } from "../../component/drawer/PrivacyTermDrawer"
import { Errors } from "../../contexts/ErrorContext"
import { InquiryService } from "../../service/InquiryService"

export const Inquiry = () => {
    const [form] = Form.useForm()

    const [isLoading, setLoading] = useState<boolean>(false)

    const [subscribe, setSubscribe] = useState(false)
    const [privacy, setPrivacy] = useState(false)
    const [showPrivacyDrawer, setPrivacyDrawer] = useState(false)

    const handleCreate = () => {
        form.validateFields()
            .then(async (values) => {
                Modal.confirm({
                    title: "문의를 등록하시겠습니까?",
                    okText: "확인",
                    cancelText: "취소",
                    transitionName: "",
                    maskTransitionName: "",
                    onOk: async () => {
                        setLoading(true)

                        const data = {
                            ...values,
                            subscribe: subscribe,
                        }

                        const response = await InquiryService.create(data)
                        if (response.status === 200) {
                            message.success('문의가 등록되었습니다.', 2, () => {
                                form.resetFields()
                                setPrivacy(false)
                                setSubscribe(false)
                            })
                        } else {
                            Errors.AjaxError(response.data)
                        }
                        setLoading(false)
                    },
                })
            })
            .catch((e) => {
                console.log('error', e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Form
            form={form}
            layout="vertical"
            style={{
                cursor: 'pointer'
            }}
        >
            <Row gutter={[16, 0]} className="inquiry">
                <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, }}>
                    <Form.Item
                        label={"성함"}
                        name={"username"}
                        rules={[{ required: true, message: "성함을 입력해 주세요." }]}
                    >
                        <Input size="large" placeholder="성함을 입력해 주세요." />
                    </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, }}>
                    <Form.Item
                        label={"회사명"}
                        name={"company"}
                        rules={[{ required: true, message: "회사명을 입력해 주세요." }]}
                    >
                        <Input size="large" placeholder="회사명을 입력해 주세요." />
                    </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, }}>
                    <Form.Item
                        label={"전화번호"}
                        name={"phone"}
                        rules={[{ required: true, message: "전화번호를 입력해 주세요." }]}
                    >
                        <Input size="large" placeholder="전화번호를 입력해 주세요." />
                    </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 12, lg: 12, }}>
                    <Form.Item
                        label={"이메일"}
                        name={"email"}
                        rules={[{ required: true, message: "이메일을 입력해 주세요." }]}
                    >
                        <Input size="large" placeholder="이메일을 입력해 주세요." />
                    </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, }}>
                    <Form.Item
                        label={"사이트"}
                        name={"site"}
                    >
                        <Input size="large" placeholder="사이트를 입력해 주세요." />
                    </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, }}>
                    <Form.Item
                        label={"문의 사항"}
                        name={"content"}
                    >
                        <TextArea
                            rows={6}
                            size="large"
                            maxLength={300}
                            placeholder="문의 내용을 입력 해주세요(최대 200자)"
                        />
                    </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, }}>
                    <Typography.Text>
                        아래 링크를 눌러 내용을 주의 깊게 읽으세요. <br />
                        체크박스를 선택하면, 다음 항목을 모두 읽고 동의한 것으로 간주합니다.
                    </Typography.Text>
                </Col>
                <Divider />
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, }}>
                    <Form.Item
                        name="subscribe"
                        style={{ marginBottom: 0, }}
                    >
                        <Flex justify="space-between">
                            <Checkbox
                                checked={subscribe}
                                onClick={() => {
                                    setSubscribe(!subscribe)
                                }}
                            >
                                <Typography.Text style={{ fontWeight: 300 }}>
                                    소개서, 소식 등 정보수신에 동의합니다.
                                </Typography.Text>
                            </Checkbox>
                        </Flex>
                    </Form.Item>
                    <Form.Item
                        name="privacy"
                        rules={[{ required: true, message: "개인정보 수집 동의해 주세요." }]}
                    >
                        <Flex justify="space-between">
                            <Checkbox
                                checked={privacy}
                                onClick={() => {
                                    setPrivacy(!privacy)
                                }}
                            >
                                <Typography.Text style={{ fontWeight: 300 }}>
                                    [필수] 개인정보 수집 및 이용에 동의합니다.
                                </Typography.Text>
                            </Checkbox>
                            <Typography.Link
                                onClick={() => {
                                    setPrivacyDrawer(true)
                                }}
                            >
                                {'[약관보기]'}
                            </Typography.Link>
                        </Flex>
                    </Form.Item>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, }} style={{ marginTop: 24, }}>
                    <Flex justify="center">
                        <Button
                            size="large"
                            type="primary"
                            style={{
                                minWidth: 346,
                            }}
                            loading={isLoading}
                            onClick={handleCreate}
                        >
                            문의하기
                        </Button>
                    </Flex>
                </Col>
            </Row>
            <PrivacyTermDrawer
                show={showPrivacyDrawer}
                onClosed={() => {
                    setPrivacyDrawer(false)
                }}
            />
        </Form>
    )
}