import { Card, Col, Flex, Layout, Row, Timeline, Typography } from "antd"

export const History = () => {
    const items = [
        {
            year: '2018',
            title: '생각하는 늑대 법인 설립',
            histories: [
                '2018.01 법인 설립 (송파구)',
                '네이버, 카카오 공식 대대행사 등록 (NHN AD)',
                '네모 APP 종합광고대행 계약',
                '윤선생베이직 디지털 광고대행 계약',
                '한화 갤러리아몰 디지털 SNS부문 대행 계약',
                '위스키 브랜드 맥캘란 디지털 광고대행 계약',
                '인테리어 서비스 집닥 디지털 광고대행 계약',
                'NC소프트 타이니키즈카페 광고대행 계약',
                'KT Airmapkorea 디지털 광고대행 계약',
            ]
        },
        {
            year: '2019',
            title: '본사이전',
            histories: [
                '2019.01 본사이전 (강남구 테헤란로 82길 15)',
                '시원스쿨 바이럴마케팅 대행계약',
                '퍼포먼스 마케팅 신기술투자조합결성 (더인벤션랩)',
                'KT, NC소프트, 국가기술표준원 디지털 광고대행 계약',
                '비엣젯항공 디지털 마케팅 대행계약',
                '베트남 페이스북 페이지 100만 팔로워 달성',
                '내셔널 지오그래픽 어패럴 디지털 광고대행 계약',
                '베트남 데이터 솔루션 포인트 테크놀로지 시드투자',
            ]
        },
        {
            year: '2020',
            title: '글로벌 사업 도전 (포인트 플랫폼 개발 착수)',
            histories: [
                '디지털 퍼스트 광고협업(웨이브)',
                '초기 창업 패키지 선정 (SNOWCASH)',
                '유토소프트 디지털 광고대행 계약',
                '벤처기업협회 디지털 광고대행 계약',
                '김캐디, 리더스코스메틱 디지털광고대행 계약',
                '롯데면세점 베트남 디지털마케팅 대행 계약',
                '심리상담서비스 트로스트 광고대행 계약',
                '삼진제약 디지털마케팅 대행 계약',
            ]
        },
        {
            year: '2021',
            title: '광고대행사에서 애드테크 전문기업으로',
            histories: [
                '마이어코리아 디지털 광고대행 계약',
                '㈜LF 디지털 광고대행 계약',
                '엘라고코리아 디지털 마케팅 대행 계약',
                '4월 초기창업패키지 최우수기업 선정(단국대학교)',
                '듀베티카 디지털 광고대행 계약',
                '홈서비스 MISO 디지털 마케팅 계약',
                '투자기관 MOU 체결(더인벤션랩)',
                '베트남 풀필먼트 MOU 체결(고미코퍼레이션)',
                '베트남 포인트 플랫폼 SNOWCASH 런칭',
                '카카오헤어샵 디지털 마케팅 대행 계약',
                '미스터피자 디지털 마케팅 대행 계약',
                '더카트골프 디지털 마케팅 대행 계약',
            ]
        },
        {
            year: '2022',
            title: '패션,커머스 전문 퍼포먼스 대행사',
            histories: [
                '코오롱 G (지포어, 잭니, 엘로드, 골든베어) 광고 대행 계약',
                '베럴 디지털 광고 대행',
                '한국 에자이 코리아 헬피 디지털 광고 대행 계약',
                '국가기술표준원 디지털 광고 대행 계약',
                '브라이트 에너지 파트너스 광고 대행 계약',
                'AI 기업 알체라 주식회사 업무 계약',
                '울랄라랩 홍보 영상 제작',
                'KoDATA TCB 기술등급 T5 평가 (기술력 상위 기업)',
                '코오롱 시리즈, 커스텀멜로우 디지털 광고 대행 계약',
                '에피그램, 헨리코튼, 레코드 디지털 광고 대행 계약',
            ]
        },
        {
            year: '2023',
            title: 'AI 기술을 퍼포먼스, 콘텐츠 분야 도입',
            histories: [
                '코오롱 하이드아웃 디지털 광고 대행 계약',
                '바로연 결혼 정보 회사 디지털 광고 대행 계약',
                'GS커넥트 디지털 광고 대행 계약',
                '프리즘 디지털 광고 대행 계약',
            ]
        },
    ]

    return (
        <Row className="history">
            <Col span={24}>
                <Flex justify="center">
                    <Typography.Title level={1} style={{ fontSize: 50, }}>
                        History
                    </Typography.Title>
                </Flex>
            </Col>
            <Col {...{ xs: 0, sm: 0, md: 0, lg: 24 }}>
                <Layout.Content style={{ marginTop: 40, }}>
                    <Timeline
                        mode="alternate"
                        items={items.map((i, idx: number) => {
                            return {
                                children: (
                                    <Row key={i.year} data-aos={idx % 2 === 0 ? 'fade-left' : 'fade-right'}>
                                        <Col span={24}>
                                            <Typography.Title level={2}>
                                                {`${i.year}년`}
                                            </Typography.Title>
                                        </Col>
                                        <Col span={24}>
                                            <Flex justify={idx % 2 === 0 ? 'start' : 'end'}>
                                                <Card
                                                    size="small"
                                                    className={`item ${idx % 2 === 0 ? '' : 'rtl'}`}
                                                >
                                                    <Typography.Title level={4}>{i.title}</Typography.Title>
                                                    <Typography.Paragraph>
                                                        <ul>
                                                            {i.histories.map((h) => {
                                                                return (
                                                                    <li key={h}>{h}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </Typography.Paragraph>
                                                </Card>
                                            </Flex>
                                        </Col>
                                    </Row>
                                )
                            }
                        })}
                    />
                </Layout.Content>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 24, lg: 0 }}>
                <Layout.Content style={{ marginTop: 40, }}>
                    <Timeline
                        mode="left"
                        items={items.map((i, idx: number) => {
                            return {
                                children: (
                                    <Row key={i.year} data-aos={'fade'}>
                                        <Col span={24}>
                                            <Typography.Title level={2}>
                                                {`${i.year}년`}
                                            </Typography.Title>
                                        </Col>
                                        <Col span={24}>
                                            <Flex justify={idx % 2 === 0 ? 'start' : 'end'}>
                                                <Card
                                                    size="small"
                                                    className={`item ${window.innerWidth > 576 ? (idx % 2 === 0 ? 'ltr' : 'rtl') : 'ltr'}`}
                                                >
                                                    <Typography.Title level={4}>{i.title}</Typography.Title>
                                                    <Typography.Paragraph>
                                                        <ul>
                                                            {i.histories.map((h) => {
                                                                return (
                                                                    <li key={h}>{h}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </Typography.Paragraph>
                                                </Card>
                                            </Flex>
                                        </Col>
                                    </Row>
                                )
                            }
                        })}
                    />
                </Layout.Content>
            </Col>
        </Row>
    )
}