import { Col, Descriptions, Divider, Drawer, Layout, Row, Space, Typography } from "antd"
import { FunctionComponent } from "react"
const { Text, Paragraph, Title, } = Typography

interface IProps {
    show: boolean
    onClosed: () => void
}

export const PrivacyTermDrawer: FunctionComponent<IProps> = (props) => {
    const { show, onClosed } = props
    return (
        <Drawer
            title="개인정보 활용"
            open={show}
            width={window.innerWidth < 576 ? "100%" : "50%"}
            destroyOnClose={true}
            onClose={onClosed}
        >
            <Row>
                <Col xs={{ span: 24 }} style={{ backgroundColor: '#fff', marginBottom: 30, }}>
                    <Space direction="vertical" size="large">
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={4}>개인정보 처리방침</Title>
                            <Paragraph>
                                <Text type="secondary">
                                    (주)생각하는늑대(이하 “회사”)는 정보주체의 개인정보를 중요시하며, 개인정보 보호법, 전기통신사업법 등 회사가 준수해야할 관련 법령상의 개인정보보호 규정을 준수하고 있습니다.<br />
                                    회사는 개인정보 처리방침을 통하여 처리하는 개인정보가 어떻게 이용되고 있으며, 개인정보보호를 위해 어떠한 조치를 하고 있는지 알려드립니다.<br />
                                    본 개인정보 처리방침은 회사가 제공하는 서비스에 한해 적용되며 다음과 같은 내용을 담고 있습니다.
                                </Text>
                            </Paragraph>
                            <Divider />
                            <Title level={5} style={{ color: '#999' }}>본 약관은 2024-03-21부터 적용됩니다.</Title>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>1. 개인정보의 수집목적</Title>
                            <Paragraph>
                                <ul>
                                    <li style={{ color: '#999' }}>서비스 상담 및 비즈니스 컨설팅</li>
                                    <li style={{ color: '#999' }}>마케팅을 위한 광고성 정보 전송(수신 동의자에 한함)</li>
                                </ul>
                            </Paragraph>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>2. 수집하는 개인 정보의 항목</Title>
                            <Paragraph>
                                <ul>
                                    <li style={{ color: '#999' }}>{'[필수] 성함'}</li>
                                    <li style={{ color: '#999' }}>{'[필수] 회사 명(사업체 명)'}</li>
                                    <li style={{ color: '#999' }}>{'[필수] 전화번호'}</li>
                                    <li style={{ color: '#999' }}>{'[필수] 이메일'}</li>
                                    <li style={{ color: '#999' }}>{'사이트'}</li>
                                </ul>
                            </Paragraph>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>3. 개인정보의 보유, 이용 기간</Title>
                            <Paragraph>
                                <ul>
                                    <li style={{ color: '#999' }}>{'전자상거래 등에서의 소비자 보호에 관한 법률에 의거하여 5년간 보유합니다.'}</li>
                                </ul>
                            </Paragraph>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>4. 개인정보의 제3자 제공에 관한 사항</Title>
                            <Paragraph>
                                <ul>
                                    <li style={{ color: '#999' }}>{'개인정보 보호법 제 17조 및 제 18조에 해당하는 경우에 제 3자에게 제공합니다.'}</li>
                                    <li style={{ color: '#999' }}>{'정보 주체로부터 동의를 받는경우 제 3자에게 제공합니다.'}</li>
                                    <li style={{ color: '#999' }}>{'법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우'}</li>
                                </ul>
                            </Paragraph>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>5. 개인정보의 파기 절차 및 방법</Title>
                            <Paragraph>
                                <ul>
                                    <li style={{ color: '#999' }}>{'회사는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.'}</li>
                                    <li style={{ color: '#999' }}>{'회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호 책임자의 승인을 받아 개인정보를 파기합니다.'}</li>
                                    <li style={{ color: '#999' }}>{'회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.'}</li>
                                </ul>
                            </Paragraph>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>6. 정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항</Title>
                            <Paragraph>
                                <ul>
                                    <li style={{ color: '#999' }}>
                                        {'정보주체는 회사에 대해 언제든지 다음 각호의 개인정보보호 관련 권리를 행사할 수 있습니다.'}
                                        <ul>
                                            <li>{'개인정보 열람 요구'}</li>
                                            <li>{'오류 등이 있을 경우 정정 요구'}</li>
                                            <li>{'삭제 요구'}</li>
                                            <li>{'처리정지 요구'}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </Paragraph>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>7. 개인정보 보호 책임자</Title>
                            <Descriptions
                                bordered
                                size="small"
                                // layout="vertical"
                                column={1}
                                labelStyle={{ width: '30%', textAlign: 'center' }}
                            >
                                <Descriptions.Item label={'성명'}>
                                    <Typography.Text>김성경</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={'직책'}>
                                    <Typography.Text>CTO</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={'연락처'}>
                                    <Typography.Text>sk.kim@thinkingwolf.co.kr</Typography.Text>
                                </Descriptions.Item>
                            </Descriptions>
                        </Layout.Content>
                        <Layout.Content style={{ backgroundColor: '#fff' }}>
                            <Title level={5}>8. 권익 침해 구제방법</Title>
                            <Descriptions
                                bordered
                                size="small"
                                column={1}
                                labelStyle={{ width: '30%', textAlign: 'center' }}
                            >
                                <Descriptions.Item label={'기관'}>
                                    <Typography.Text>개인정보분쟁조정위원회</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={'홈페이지'}>
                                    <Typography.Link
                                        href="www.kopico.go.kr"
                                        target="_blank"
                                    >
                                        www.kopico.go.kr
                                    </Typography.Link>
                                </Descriptions.Item>
                                <Descriptions.Item label={'전화번호'}>
                                    <Typography.Link
                                        href="tel:1833-6972"
                                        target="_blank"
                                    >
                                        1833-6972
                                    </Typography.Link>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                                bordered
                                size="small"
                                column={1}
                                labelStyle={{ width: '30%', textAlign: 'center' }}
                                style={{ marginTop: 12, }}
                            >
                                <Descriptions.Item label={'기관'}>
                                    <Typography.Text>개인정보침해 신고센터</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={'홈페이지'}>
                                    <Typography.Link
                                        href="privacy.kisa.or.kr"
                                        target="_blank"
                                    >
                                        privacy.kisa.or.kr
                                    </Typography.Link>
                                </Descriptions.Item>
                                <Descriptions.Item label={'전화번호'}>
                                    <Typography.Link
                                        href="tel:118"
                                        target="_blank"
                                    >
                                        118
                                    </Typography.Link>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                                bordered
                                size="small"
                                column={1}
                                labelStyle={{ width: '30%', textAlign: 'center' }}
                                style={{ marginTop: 12, }}
                            >
                                <Descriptions.Item label={'기관'}>
                                    <Typography.Text>대검찰청</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={'홈페이지'}>
                                    <Typography.Link
                                        href="www.spo.go.kr"
                                        target="_blank"
                                    >
                                        www.spo.go.kr
                                    </Typography.Link>
                                </Descriptions.Item>
                                <Descriptions.Item label={'전화번호'}>
                                    <Typography.Link
                                        href="tel:1301"
                                        target="_blank"
                                    >
                                        1301
                                    </Typography.Link>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                                bordered
                                size="small"
                                column={1}
                                labelStyle={{ width: '30%', textAlign: 'center' }}
                                style={{ marginTop: 12, }}
                            >
                                <Descriptions.Item label={'기관'}>
                                    <Typography.Text>경찰청</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label={'홈페이지'}>
                                    <Typography.Link
                                        href="ecrm.cyber.go.kr"
                                        target="_blank"
                                    >
                                        ecrm.cyber.go.kr
                                    </Typography.Link>
                                </Descriptions.Item>
                                <Descriptions.Item label={'전화번호'}>
                                    <Typography.Link
                                        href="tel:182"
                                        target="_blank"
                                    >
                                        182
                                    </Typography.Link>
                                </Descriptions.Item>
                            </Descriptions>
                        </Layout.Content>
                    </Space>
                </Col>
            </Row>
        </Drawer>
    )
}