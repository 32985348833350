import { Layout } from "antd"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { Header } from "./Header"
import { Position } from "./Position"

export const Wanted = () => {
    return (
        <MainLayout menuType={MenuType.Wanted}>
            <Layout.Content className="wanted">
                <Header />
                <Position />
            </Layout.Content>
        </MainLayout>
    )
}