import { Col, Divider, Flex, Layout, Row, Space, Typography } from "antd"
import { useEffect, useRef } from "react"
import { Inquiry } from "../inquiry/Inquiry"

export const HomeLocationAndInquiry = () => {
    const mapRef = useRef(null)
    const url = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage'

    useEffect(() => {
        const position = new kakao.maps.LatLng(37.5059877, 127.0581624);
        const mapOption = {
            center: position,
            level: 3,
        };

        const map = new kakao.maps.Map(mapRef?.current, mapOption);

        var customOverlay = new kakao.maps.CustomOverlay({
            position: position,
            content: `<a href="https://map.kakao.com/link/map/541389578" target="_blank"><img src="${url}/small_logo.png" width="120" height="120" /></a>`,
        });
        customOverlay.setMap(map);
    }, [])

    return (
        <Row className="home-location-and-inquiry">
            <Col span={24}>
                <Layout.Content>
                    <Row gutter={[0, 0]}>
                        <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, }} className="location">
                            <Flex justify="center">
                                <Typography.Title level={1} style={{ fontSize: 32, }}>
                                    Location
                                </Typography.Title>
                            </Flex>
                            <Flex className="info">
                                <Space size={4} direction="vertical">
                                    <Typography.Text>
                                        <Typography.Link
                                            href="tel: 025383602"
                                        >
                                            TEL: +82 02.538.3602
                                        </Typography.Link>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Typography.Link
                                            href="tel: 025383602"
                                        >
                                            FAX: +82 02.538.3602
                                        </Typography.Link>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Typography.Text>
                                            MAIL: contact@thinkingwolf.co.kr
                                        </Typography.Text>
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Typography.Link
                                            href="https://map.kakao.com/link/map/541389578"
                                            target="_blank"
                                        >
                                            서울특별시 강남구 테헤란로 82길 15, 디아이타워 14층
                                        </Typography.Link>
                                    </Typography.Text>
                                </Space>
                            </Flex>
                            <div className="map" ref={mapRef}></div>
                        </Col>
                        <Col {...{ xs: 24, sm: 0, md: 0, lg: 0, xl: 0, }} style={{ marginTop: 24, }}>
                            <Divider className="divider" />
                        </Col>
                        <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12, }} className="contact">
                            <Row style={{ marginBottom: 24, }}>
                                <Col span={24}>
                                    <Flex justify="center">
                                        <Typography.Title level={1} style={{ fontSize: 32, }}>
                                            GET IN TOUCH WITH US
                                        </Typography.Title>
                                    </Flex>
                                </Col>
                            </Row>
                            <Inquiry />
                        </Col>
                    </Row>
                </Layout.Content>
            </Col>
        </Row>
    )
}