import { AxiosRequestConfig } from "axios";
import AxiosContext from "../contexts/AxiosContext";

export const ReferenceService = {
    reference: async (id: string) => {
        try {
            const { data, status } = await AxiosContext.get(`/references/${id}`)
            return { data: data, status: status }
        } catch (error) {
            return { error: error }
        }
    },
    search: async (model: any) => {
        try {
            const config: AxiosRequestConfig = {
                params: model
            }

            const { data, status } = await AxiosContext.get(`/references`, config)
            return { data: data, status: status }
        } catch (error) {
            return { error: error }
        }
    },
}