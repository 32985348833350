import { Card, Col, Flex, Layout, Pagination, Row, Spin, Typography } from "antd"
import { sample } from "lodash-es"
import { useEffect, useState } from "react"
import { ReferenceModal } from "../../component/modal/ReferenceModal"
import { Errors } from "../../contexts/ErrorContext"
import { PaginationModel } from "../../model/PaginationModel"
import { ReferenceModel } from "../../model/ReferenceModel"
import { ReferenceService } from "../../service/ReferenceService"

export const Reference = () => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<PaginationModel<ReferenceModel>>()
    const [id, setId] = useState<string | null>(null)

    const search = async (page: number = 1) => {
        setLoading(true)

        const response = await ReferenceService.search({
            page: page,
        })

        if (response.status === 200) {
            setPage(response.data.item)
        } else {
            Errors.AjaxError(response.data)
        }

        setLoading(false)
    }

    useEffect(() => {
        search()
    }, [])

    return (
        <Row gutter={[0, 16]} className="reference">
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Spin spinning={isLoading}>
                        <Row gutter={[12, 12]} justify={'center'}>
                            {page?.items.map((d) => {
                                return (
                                    <Col
                                        key={d.id}
                                        {...{ xs: 24, md: 10, lg: 7, xl: 7, xxl: 7 }}
                                        data-aos={"fade-up"}
                                        data-aos-duration={sample([1000, 1300, 1500])}
                                    >
                                        <Card
                                            hoverable
                                            key={d.title}
                                            className="item-container "
                                            onClick={() => {
                                                setId(d.id)
                                            }}
                                        >
                                            <Row className="item" align={"middle"}>
                                                <Col span={24} className="title">
                                                    <Typography.Text>
                                                        {d.title}
                                                    </Typography.Text>
                                                </Col>
                                                <Col
                                                    span={24}
                                                    className="content"
                                                >
                                                    <Typography.Text style={{ letterSpacing: "-0.5px" }}>
                                                        <span dangerouslySetInnerHTML={{ __html: d.subtitle }} />
                                                    </Typography.Text>
                                                </Col>
                                            </Row>
                                            {d.thumbnail && (
                                                <img
                                                    src={d.thumbnail.middleUrl}
                                                    style={{
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        zIndex: 1,
                                                        width: '100%',
                                                        height: '100%',
                                                        position: 'absolute',
                                                        objectFit: 'cover',
                                                        objectPosition: 'center',
                                                    }}
                                                    alt="레퍼런스 이미지"
                                                />
                                            )}
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Spin>
                </Layout.Content>
            </Col>
            <Col span={24} style={{ marginTop: 24, }}>
                <Flex justify="center">
                    <Pagination
                        defaultCurrent={1}
                        current={page?.currentPageIndex}
                        total={page?.totalItemCount}
                        pageSize={page?.size}
                        onChange={(page: number) => {
                            search(page)
                        }}
                    />
                </Flex>
            </Col>

            <ReferenceModal
                id={id}
                show={id != null}
                onClosed={() => {
                    setId(null)
                }}
            />
        </Row>
    )
}