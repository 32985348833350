import AxiosContext from "../contexts/AxiosContext"

export const InquiryService = {
    create: async (payload: { title: string, content: string }) => {
        try {
            const { data, status } = await AxiosContext.post(`/inquiries`, payload)
            return { data: data, status: status }
        } catch (error) {
            return { error: error }
        }
    },
}