import { FacebookFilled } from '@ant-design/icons'
import { Button, Col, Layout, Row, Space, Typography } from "antd"
import { useEffect } from "react"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { Header } from "./Header"

declare module window {
    const FB: any
    const innerWidth: number
}

export const Login = () => {
    // const [isLoading, setLoading] = useState<boolean>(false)

    const handleFacebookLogin = async (accessToken: string) => {
        // setLoading(true)
        // const response = await AccountService.facebook(accessToken)
        // if (response.status === 200) {
        //     // handler.login(response.data.item)
        //     setLoading(false)
        // } else {
        //     setLoading(false)
        //     Errors.AjaxError(response.data)
        // }
    }

    useEffect(() => {
        // window.FB.init({
        //     // appId: '374619438679887', // production
        //     // appId: '3269967149971408', // development
        //     appId: isProduction ? '374619438679887' : '3269967149971408',
        //     xfbml: true,
        //     version: 'v19.0'
        // })
    }, [])

    return (
        <MainLayout menuType={MenuType.Login}>
            <Layout.Content className="login">
                <Header />
                <Row gutter={[0, 16]} justify={"center"} className="body">
                    <Col
                        {...{ xs: 24, sm: 18, md: 14, lg: 10, xl: 10, xxl: 7, }}
                        style={{
                            minHeight: window.innerWidth > 576 ? 200 : 400,
                            alignContent: 'center',
                        }}
                    >
                        <Button
                            block
                            size='large'
                            // loading={isLoading}
                            style={{ backgroundColor: '#4267b2', color: '#fff' }}
                            onClick={() => {
                                window.FB.login((r: { authResponse: { accessToken: string, userID: string } }) => {
                                    if (r.authResponse?.accessToken) {
                                        handleFacebookLogin(r.authResponse.accessToken)
                                    }
                                })
                            }}
                        >
                            <Space>
                                <FacebookFilled />
                                <Typography.Text style={{ color: '#fff' }}>
                                    페이스북 로그인
                                </Typography.Text>
                            </Space>
                        </Button>
                    </Col>
                </Row>
            </Layout.Content>
        </MainLayout>
    )
}