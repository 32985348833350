import { Col, Flex, Modal, Row, Space, Spin, Tag, Typography } from "antd"
import { FunctionComponent, useEffect, useState } from "react"
import { Errors } from "../../contexts/ErrorContext"
import { ReferenceModel } from "../../model/ReferenceModel"
import { ReferenceService } from "../../service/ReferenceService"
import { ReferenceMediaType } from "../../type/ReferenceMediaType"

interface IProps {
    id: string | null
    show: boolean
    onClosed: () => void
}

export const ReferenceModal: FunctionComponent<IProps> = (props) => {
    const { id, show, onClosed } = props

    const [isLoading, setLoading] = useState<boolean>(false)
    const [r, setReference] = useState<ReferenceModel>()

    const detail = async () => {
        setLoading(true)
        const response = await ReferenceService.reference(id!!)
        if (response.status === 200) {
            setReference(response.data.item)
        } else {
            Errors.AjaxError(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (id) {
            detail()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <Modal
            title={'생각하는늑대 경쟁PT 수주'}
            open={show}
            centered
            destroyOnClose={true}
            onCancel={() => { onClosed() }}
            footer={null}
            className="reference"
            transitionName=""
            maskTransitionName=""
            width={window.innerWidth > 576 ? '40%' : '100%'}
        >
            <Spin spinning={isLoading}>
                <Row gutter={[0, 16]} style={{ marginTop: 24, }}>
                    <Col {...{ xs: 24, md: 12 }}>
                        <Flex justify="start" align="center" style={{ height: '100%' }}>
                            {r?.url ?
                                <Typography.Link
                                    strong
                                    href={r.url}
                                    target="_blank"
                                    style={{ fontSize: 16, }}
                                >
                                    {r?.title}
                                </Typography.Link> :
                                <Typography.Text
                                    strong
                                    style={{ fontSize: 16, }}
                                >
                                    {r?.title}
                                </Typography.Text>
                            }
                        </Flex>
                    </Col>
                    <Col {...{ xs: 24, md: 12 }}>
                        <Flex justify="end" align="center">
                            <Space>
                                {r?.referenceMediaTypes.map((c) => {
                                    let color = 'gray'
                                    switch (c.t) {
                                        case ReferenceMediaType.performance_marketing:
                                            color = "geekblue"
                                            break
                                        case ReferenceMediaType.app_performance_marketing:
                                            color = "purple"
                                            break
                                        case ReferenceMediaType.branding:
                                            color = "cyan"
                                            break
                                        case ReferenceMediaType.content_marketing:
                                            color = "green"
                                            break
                                        case ReferenceMediaType.imc:
                                            color = "magenta"
                                            break
                                        case ReferenceMediaType.viral:
                                            color = "pink"
                                            break
                                    }

                                    return (
                                        <Space key={c.t}>
                                            <Tag
                                                color={color}
                                                style={{
                                                    margin: 0,
                                                    padding: '4px 8px'
                                                }}
                                            >
                                                {c.displayName}
                                            </Tag>
                                        </Space>
                                    )
                                })}
                            </Space>
                        </Flex>
                    </Col>
                    <Col span={24} className="img">
                        <Flex justify="center">
                            <img
                                src={r?.thumbnail?.largeUrl}
                                alt="썸네일"
                            />
                        </Flex>
                        <div className="description">
                            <Typography.Text
                                strong
                            >
                                {r?.description}
                            </Typography.Text>
                        </div>
                        <div className="cover" />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}