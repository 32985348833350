import { Card, Col, Flex, Layout, Row, Typography } from "antd"
import { useRef } from "react"
import Slider, { Settings } from "react-slick"

export const HomeOrganization = () => {
    const sliderRef = useRef<any>()

    const url = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage'
    const organizations = [
        {
            title: 'Our\nTeam',
            content: '최고의 팀플레이를 만들어내는 생각하는 늑대',
        },
        {
            title: 'Campaign',
            subtitle: '캠페인 본부',
            content: '디지털캠페인 운영 및 집행\n신규 매체 발굴 및 미디어 믹스 제안\n마케팅 KPI 관리',
            background: `${url}/campaign.jpg`,
        },
        {
            title: 'Design',
            subtitle: '디자인 유닛',
            content: '종합 캠페인 기획 및 운영\nSNS 채널 운영 및 콘텐츠 기획/제작\n디자인,영상 및 작화 창작',
            background: `${url}/design.jpg`,
        },
        {
            title: 'Management Support',
            subtitle: '경영지원',
            content: '경영 기획 및 재무 관리\n퍼포먼스, 캠페인 기획 업무 지원',
            background: `${url}/support.jpg`,
        },
    ]
    const settings: Settings = {
        // infinite: true,
        speed: 600,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1201,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 542,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    return (
        <Row className="home-organization">
            <Col span={24}>
                <Flex justify="center">
                    <Typography.Title level={1} style={{ fontSize: 50, }}>
                        Organization
                    </Typography.Title>
                </Flex>
            </Col>
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Slider ref={sliderRef} {...settings}>
                        {organizations.map((d, idx: number) => {
                            return (
                                <Card
                                    hoverable
                                    key={d.title}
                                    className={`item-container ${d.background ? '' : 'no-background'}`}
                                >
                                    <Row className="item">
                                        <Col span={24} className="title">
                                            <Typography.Text>
                                                {d.title}
                                            </Typography.Text>
                                        </Col>
                                        <Col span={24} className="subtitle">
                                            <Typography.Text>
                                                {d.subtitle}
                                            </Typography.Text>
                                        </Col>
                                        <Col
                                            span={24}
                                            className="content"
                                        >
                                            <Typography.Text style={{ letterSpacing: "-0.5px" }}>
                                                <span dangerouslySetInnerHTML={{ __html: d.content }} />
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                    {d.background && (
                                        <img
                                            src={d.background}
                                            style={{
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                zIndex: 1,
                                                height: 310,
                                                position: 'absolute',
                                                objectFit: 'cover',
                                                objectPosition: 'center',
                                            }}
                                            alt="회사의 목표 이미지"
                                        />
                                    )}
                                </Card>
                            )
                        })}
                    </Slider>
                </Layout.Content>
            </Col>
        </Row>
    )
}