import { Card, Col, Flex, Image, Layout, Row, Typography } from "antd"
import { useRef } from "react"
import Slider, { Settings } from "react-slick"

export const HomeService = () => {
    const sliderRef = useRef<any>()

    const url = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage'
    const services = [
        {
            title: 'On-line AD',
            content: '맞춤형 매체 제안을 통하여 최적의 온라인 광고 미디어 플래닝을 제공합니다',
            icon: `${url}/ad.png`,
            background: `${url}/background.png`,
        },
        {
            title: 'Native AD',
            content: '콘텐츠 제작을 통한 SNS채널 운영 및 광고를 집행합니다.',
            icon: `${url}/hamburger.png`,
            background: `${url}/background.png`,
        },
        {
            title: 'Media & Analytics',
            content: '정확한 성과 측정 및 목표 달성을 위한 미디어 전략을 수립합니다.',
            icon: `${url}/chart.png`,
            background: `${url}/background.png`,
        },
        {
            title: 'Off-line AD',
            content: '타겟층의 생활패턴을 분석하여 지역 내 광고부터 전국 광고까지 다양한 매체 믹스를 제공합니다.',
            icon: `${url}/info.png`,
            background: `${url}/background.png`,
        },
        {
            title: 'Video AD',
            content: '다양한 온라인 영상 매체를 활용한 브랜딩 광고를 만듭니다.',
            icon: `${url}/movie.png`,
            background: `${url}/background.png`,
        },
    ]
    const settings: Settings = {
        // infinite: true,
        speed: 600,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        // autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
            {
                breakpoint: 3500,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 2500,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1201,
                settings: {
                    slidesToShow: 3.1,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 542,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    return (
        <Row className="home-service">
            <Col span={24}>
                <Flex justify="center">
                    <Typography.Title level={1} style={{ fontSize: 50, }}>
                        Service
                    </Typography.Title>
                </Flex>
            </Col>
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Slider ref={sliderRef} {...settings}>
                        {services.map((d, idx: number) => {
                            return (
                                <Card
                                    hoverable
                                    key={d.title}
                                    className="item-container">
                                    <Row className="item">
                                        <Col span={24}>
                                            <Image src={d.icon} preview={false} />
                                        </Col>
                                        <Col span={24}>
                                            <Typography.Text
                                                strong
                                                style={{
                                                    fontSize: 24,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {d.title}
                                            </Typography.Text>
                                        </Col>
                                        <Col
                                            span={24}
                                            className="content"
                                        >
                                            <Typography.Text style={{ letterSpacing: "-0.5px" }}>
                                                <span dangerouslySetInnerHTML={{ __html: d.content }} />
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                    {idx % 2 === 0 && (
                                        <img
                                            src={d.background}
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                width: '100%',
                                            }}
                                            alt="생각하는늑대 팀 소개 이미지"
                                        />
                                    )}
                                </Card>
                            )
                        })}
                    </Slider>
                </Layout.Content>
            </Col>
            {/* <Col span={24} style={{ marginTop: 50 }}>
                <Space size={20}>
                    <div
                        className="left-arrow"
                        onClick={() => {
                            sliderRef.current.slickPrev()
                        }}
                    >
                        <img src={assets.svg.arrow.stroke.white.left} />
                    </div>
                    <div
                        className="right-arrow"
                        onClick={() => {
                            sliderRef.current.slickNext()
                        }}
                    >
                        <img src={assets.svg.arrow.stroke.white.right} />
                    </div>
                </Space>
            </Col> */}
        </Row>
    )
}