import { Col, Layout, Row, Space, Typography } from "antd"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { Header } from "./Header"
import { Inquiry } from "../inquiry/Inquiry"

export const Contact = () => {
    return (
        <MainLayout menuType={MenuType.Contact}>
            <Layout.Content className="contact">
                <Header />
                <Row gutter={[0, 16]} className="inquire">
                    <Col span={24} style={{ marginTop: 45, }}>
                        <Layout.Content>
                            <Row gutter={[12, 12]} justify={'center'}>
                                <Col span={24} className="title">
                                    <Space direction="vertical">
                                        <Typography.Text strong>
                                            저희에게 알려주세요!
                                        </Typography.Text>
                                        <Typography.Text strong>
                                            <Typography.Text
                                                style={{
                                                    color: '#F8B31A'
                                                }}
                                            >
                                                생각하는 늑대
                                            </Typography.Text>
                                            가 도와드리겠습니다.
                                        </Typography.Text>
                                    </Space>
                                </Col>
                                <Col span={24} style={{ marginTop: 32, }}>
                                    <Inquiry />
                                </Col>
                            </Row>
                        </Layout.Content>
                    </Col>
                </Row>

            </Layout.Content>
        </MainLayout>
    )
}