import { Col, Flex, Row, Space, Typography } from "antd"

export const Header = () => {
    const height = window.innerHeight
    const width = window.innerWidth

    return (
        <Row className="header">
            <Col
                span={24}
                className="parallax"
                style={{
                    height: width > 576 ? height / 2 : height,
                    backgroundPosition: width > 576 ? 'center' : 'right',
                }}
            >
                <Row>
                    <Col
                        {...{ xs: 0, sm: 0, md: 24, lg: 24 }}
                        style={{
                            height: width > 576 ? height / 2 : height,
                            alignContent: 'center'
                        }}
                    >
                        <Flex justify="center" align="center" style={{ height: '100%' }}>
                            <Space size={'large'} direction="vertical" style={{ textAlign: 'center' }}>
                                <Typography.Title level={1} style={{ color: '#fff', fontSize: 52, }}>
                                    Wanted
                                </Typography.Title>
                                <Typography.Title level={4} style={{ color: '#fff', fontWeight: 700, }}>
                                    우리는 습관적인 생각을 거부하는 다듬어지지 않은 거친 늑대,<br />
                                    노는일을 결코 소홀히 하지 않는 즐거운 늑대를 찾습니다.
                                </Typography.Title>
                            </Space>
                        </Flex>
                    </Col>
                    <Col
                        {...{ xs: 24, sm: 24, }}
                        style={{
                            height: width > 576 ? height / 2 : height,
                            alignContent: 'center'
                        }}
                    >
                        <Flex justify="center" align="center">
                            <Space size={'large'} direction="vertical" style={{ textAlign: 'center' }}>
                                <Typography.Title level={1} style={{ color: '#fff', fontSize: 40, }}>
                                    Wanted
                                </Typography.Title>
                                <Typography.Title level={4} style={{ color: '#fff', fontWeight: 700, }}>
                                    우리는 습관적인 생각을 거부하는 <br />
                                    다듬어지지 않은 거친 늑대,<br />
                                    <br />
                                    노는일을 결코 소홀히 하지 않는<br />
                                    즐거운 늑대를 찾습니다.
                                </Typography.Title>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}