import { Button, Card, Col, Divider, Flex, Layout, Row, Space, Spin, Typography, Image } from "antd"
import React, { useEffect, useState } from "react"
import { PositionType } from "../../type/PositionType"
import { ArrowRightOutlined } from '@ant-design/icons'
import { ImageAsset } from "../../assets/images"

export const Position = () => {
    const [isLoading, setLoading] = useState<boolean>(false)
    // const [page, setPage] = useState<PaginationModel<ReferenceModel>>()
    const [positions, setPositinons] = useState<Array<any>>([])
    const [positionType, setPositionType] = useState<PositionType | null>(null)

    const types = [
        { text: '전체', type: null },
        { text: '퍼포먼스 마케팅 AE', type: PositionType.performance_ae, },
        { text: '소셜 디지털 마케팅 AE', type: PositionType.social_digital_ae, },
        { text: '광고/마케팅 디자이너', type: PositionType.desiner, },
    ]

    const search = async (page: number = 1) => {
        setLoading(true)

        // const response = await ReferenceService.search({
        //     page: page,
        // })

        // if (response.status === 200) {
        //     setPage(response.data.item)
        // } else {
        //     Errors.AjaxError(response.data)
        // }

        setLoading(false)
    }

    useEffect(() => {
        search()
    }, [])

    useEffect(() => {
        const items = [
            {
                title: '퍼포먼스 마케팅 AE',
                description: '팀장급 / 5년차 이상',
                image: ImageAsset.wanted.ae,
                url: 'https://www.notion.so/thinkingwolf/AE-5-88230190bd2049bab79ea1ccae16fef3',
                type: PositionType.performance_ae,
            },
            {
                title: '소셜 디지털 마케팅 AE',
                description: '팀장급 / 5년차 이상',
                image: ImageAsset.wanted.social_ae,
                url: 'https://www.notion.so/thinkingwolf/AE-5-3d315c0bfa894dc3bf4dcd206b8a1330',
                type: PositionType.social_digital_ae,
            },
            {
                title: '퍼포먼스 마케팅 AE',
                description: '경력 / 2년차 이상',
                image: ImageAsset.wanted.ae,
                url: 'https://www.notion.so/thinkingwolf/AE-2-26a3806fa10a471e9cad18797bdb646d',
                type: PositionType.performance_ae,
            },
            {
                title: '광고/마케팅 디자이너',
                description: '팀장급 / 5년차 이상',
                image: ImageAsset.wanted.designer,
                url: 'https://www.notion.so/thinkingwolf/5-28584de44dbc4f9a86da203af87da540',
                type: PositionType.desiner,
            },
        ]

        if(positionType) {
            setPositinons(items.filter(i => i.type === positionType))
        } else {
            setPositinons(items)
        }
    }, [positionType])

    return (
        <Row gutter={[0, 16]} className="position">
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Spin spinning={isLoading}>
                        <Row gutter={[16, 16]} justify={'center'}>
                            <Col span={24}>
                                <Typography.Text strong style={{ fontSize: 36, }}>
                                    <Typography.Text
                                        style={{
                                            fontSize: 36,
                                            borderBottom: '6px solid #F8B31A'
                                        }}
                                    >
                                        현재 채용 중
                                    </Typography.Text>
                                    인 포지션
                                </Typography.Text>
                            </Col>
                            <Col span={24} style={{ marginTop: 24, }}>
                                <Space wrap>
                                    {types.map((p) => {
                                        return (
                                            <Button
                                                key={p.type}
                                                value={p.type ?? ''}
                                                type={positionType === p.type ? 'primary' : 'default'}
                                                onClick={() => {
                                                    setPositionType(p.type)
                                                }}
                                            >
                                                {p.text}
                                            </Button>
                                        )
                                    })}
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Divider style={{ borderColor: '#F8B31A' }} />
                            </Col>
                            <Col span={24}>
                                <Space direction="vertical" style={{ width: '100%' }}>
                                    {positions.map((p) => {
                                        return (
                                            <Card
                                                hoverable
                                                key={p.url}
                                                style={{
                                                    width: '100%',
                                                    border: 'none',
                                                    borderBottom: '2px solid #eee'
                                                }}
                                            >
                                                <Typography.Link
                                                    href={p.url}
                                                    target="_blank"
                                                >
                                                    <Row gutter={[0, 12]}>
                                                        <Col {...{ xs: 24, sm: 24, md: 12 }}>
                                                            <Flex justify="start" align="center">
                                                                <Space size={"large"}>
                                                                    <Image
                                                                        src={p.image}
                                                                        style={{
                                                                            width: 40,
                                                                            borderRadius: 36,
                                                                        }}
                                                                    />
                                                                    <Typography.Text
                                                                        strong
                                                                        style={{
                                                                            fontSize: 22,
                                                                        }}
                                                                    >
                                                                        {p.title}
                                                                    </Typography.Text>
                                                                </Space>
                                                            </Flex>
                                                        </Col>
                                                        <Col {...{ xs: 24, sm: 24, md: 12 }}>
                                                            <Flex justify={window.innerWidth > 576 ? 'end' : 'start'} align="center" style={{ height: '100%' }}>
                                                                <Space split={'|'}>
                                                                    <Typography.Text
                                                                        type="secondary"
                                                                        style={{
                                                                            fontSize: 18,
                                                                        }}
                                                                    >
                                                                        {p.description}
                                                                    </Typography.Text>
                                                                    <Typography.Text
                                                                        strong
                                                                        style={{
                                                                            fontSize: 18,
                                                                            color: '#F8B31A',
                                                                        }}
                                                                    >
                                                                        <Space>
                                                                            지원하기
                                                                            <ArrowRightOutlined />
                                                                        </Space>
                                                                    </Typography.Text>
                                                                </Space>
                                                            </Flex>
                                                        </Col>
                                                    </Row>
                                                </Typography.Link>
                                            </Card>
                                        )
                                    })}
                                </Space>
                            </Col>
                        </Row>
                    </Spin>
                </Layout.Content>
            </Col>
        </Row>
    )
}