import { ArrowDownOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Row, Space, Typography } from "antd"

export const Header = () => {
    const height = window.innerHeight
    const width = window.innerWidth

    return (
        <Row className="who-we-are-header">
            <Col
                span={24}
                className="parallax"
                style={{
                    // height: width > 576 ? height / 2 : height,
                    // backgroundPosition: width > 576 ? 'center' : 'left',
                }}
            >
                <Row>
                    <Col
                        span={24}
                        style={{
                            height: width > 576 ? height / 2 : height,
                            alignContent: 'center'
                        }}
                    >
                        <Flex justify="center" align="center" style={{ height: '100%' }}>
                            <Space size={'large'} direction="vertical" style={{ textAlign: 'center' }}>
                                <Typography.Title level={1} style={{ color: '#fff' }}>
                                    Who we are
                                </Typography.Title>
                                <Typography.Title level={5} style={{ color: '#fff', fontWeight: 300, }}>
                                    생각하는늑대는 어려운 것을 쉽게, 쉬운 것을 깊게, <br />
                                    깊은 것을 유쾌하게 풀어내는 디지털 마케팅 에이전시 입니다.
                                </Typography.Title>
                                <Button
                                    ghost
                                    size="large"
                                    type="primary"
                                    style={{ marginTop: 16, height: 52 }}
                                    onClick={() => {
                                        window.location.href = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/%E1%84%89%E1%85%A2%E1%86%BC%E1%84%80%E1%85%A1%E1%86%A8%E1%84%92%E1%85%A1%E1%84%82%E1%85%B3%E1%86%AB%E1%84%82%E1%85%B3%E1%86%A8%E1%84%83%E1%85%A2_%E1%84%92%E1%85%AC%E1%84%89%E1%85%A1%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5_24%E1%84%82%E1%85%A7%E1%86%AB+2%E1%84%87%E1%85%AE%E1%86%AB%E1%84%80%E1%85%B5_07.01.pdf'
                                    }}
                                >
                                    <ArrowDownOutlined />
                                    <Typography.Text
                                        strong
                                    >
                                        회사소개서 다운로드
                                    </Typography.Text>
                                </Button>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}