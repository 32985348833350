import { Divider, Layout } from "antd"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { CoreValue } from "./CoreValue"
import { Header } from "./Header"
import { History } from "./History"

export const WhoWeAre = () => {
    return (
        <MainLayout menuType={MenuType.WhoWeAre}>
            <Layout.Content className="who-we-are">
                <Header />
                <CoreValue />
                <Divider
                    style={{
                        borderWidth: 2,
                    }}
                />
                <History />
            </Layout.Content>
        </MainLayout>
    )
}