import WhiteLogo from "./logo/logo-w.png"
import Logo from "./logo/logo.png"
import SmallLogo from "./logo/small_logo.png"

import SingleWolfBackground from "./background/single_wolf.jpg"
import WolfsBackground from "./background/wolfs.jpg"

// #region service
import Ad from "./service/ad.png"
import ServiceAdBackground from "./service/background.png"
import Chart from "./service/chart.png"
import Hamburger from "./service/hamburger.png"
import Info from "./service/info.png"
import Movie from "./service/movie.png"
// #endregion

// #region organization
import Campaign from "./organization/campaign.jpg"
import Design from "./organization/design.jpg"
import Support from "./organization/support.jpg"
// #endregion

// #region partners
import Birdie from "./partner/birdie.png"
import Brentwood from "./partner/brentwood.png"
import Cambridge from "./partner/cambridge.png"
import Customellow from "./partner/customellow.png"
import Elago from "./partner/elago.png"
import Galleria from "./partner/galleria.png"
import Gfore from "./partner/gfore.png"
import Hazzys from "./partner/hazzys.png"
import Head from "./partner/head.png"
import Henrycotte from "./partner/henrycotten.png"
import Kakao_hair from "./partner/kakao_hair.png"
import Kcf from "./partner/kcf.png"
import Kt from "./partner/kt.png"
import Macallan from "./partner/macallan.png"
import Megabox from "./partner/megabox.png"
import Meyer from "./partner/meyer.png"
import Mr_pizza from "./partner/mr_pizza.png"
import Ncsoft from "./partner/ncsoft.png"
import Samjin from "./partner/samjin.png"
import Series from "./partner/series.png"
import The_cart_golf from "./partner/the_cart_golf.png"
import Vietjet from "./partner/vietjet.png"
import Waac from "./partner/waac.png"
// #endregion

// #region core-value
import CoreValue1 from "./core-value/core-value_1.jpg"
import CoreValue2 from "./core-value/core-value_2.jpg"
import CoreValue3 from "./core-value/core-value_3.jpg"
import CoreValue4 from "./core-value/core-value_4.jpg"
import CoreValue5 from "./core-value/core-value_5.jpg"
// #endregion

// #region wanted
import Ae from "./wanted/ae.png"
import Designer from "./wanted/designer.png"
import SocialAe from "./wanted/social_ae.png"
// #endregion


export {
    Logo, SingleWolfBackground, SmallLogo, WhiteLogo, WolfsBackground
}

export const ImageAsset = {
    service: {
        chart: Chart,
        hamburger: Hamburger,
        movie: Movie,
        ad: Ad,
        info: Info,
        background: {
            ad: ServiceAdBackground,
        }
    },
    organization: {
        campaign: Campaign,
        design: Design,
        support: Support,
    },
    partner: {
        galleria: Galleria,
        the_cart_golf: The_cart_golf,
        meyer: Meyer,
        macallan: Macallan,
        megabox: Megabox,
        mr_pizza: Mr_pizza,
        birdie: Birdie,
        brentwood: Brentwood,
        vietjet: Vietjet,
        samjin: Samjin,
        series: Series,
        elago: Elago,
        waac: Waac,
        gfore: Gfore,
        kakao_hair: Kakao_hair,
        cambridge: Cambridge,
        customellow: Customellow,
        ncsoft: Ncsoft,
        kcf: Kcf,
        head: Head,
        hazzys: Hazzys,
        henrycotte: Henrycotte,
        kt: Kt,
    },
    core_value: {
        core1: CoreValue1,
        core2: CoreValue2,
        core3: CoreValue3,
        core4: CoreValue4,
        core5: CoreValue5,
    },
    wanted: {
        ae: Ae,
        social_ae: SocialAe,
        designer: Designer,
    }
}
