import { Card, Col, Flex, Layout, Row, Typography } from "antd"

export const HomePartner = () => {
    const url = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage'
    const partners = [
        `${url}/the_cart_golf.png`,
        `${url}/gfore.png`,
        `${url}/waac.png`,
        `${url}/series.png`,
        `${url}/cambridge.png`,
        `${url}/customellow.png`,
        `${url}/birdie.png`,
        `${url}/head.png`,
        `${url}/kcf.png`,
        `${url}/kt.png`,
        `${url}/ncsoft.png`,
        `${url}/macallan.png`,
        `${url}/galleria.png`,
        `${url}/henrycotten.png`,
        `${url}/meyer.png`,
        `${url}/mr_pizza.png`,
        `${url}/samjin.png`,
        `${url}/elago.png`,
        `${url}/brentwood.png`,
        `${url}/vietjet.png`,
        `${url}/megabox.png`,
    ]

    return (
        <Row className="home-partner">
            <Col span={24}>
                <Flex justify="center">
                    <Typography.Title level={1} style={{ fontSize: 50, }}>
                        Partners
                    </Typography.Title>
                </Flex>
            </Col>
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Row gutter={[16, 16]}>
                        {partners.map((d, idx: number) => {
                            return (
                                <Col key={idx} {...{ xs: 24, sm: 24, md: 12, lg: 8 }}>
                                    <Card
                                        hoverable
                                        className={`item-container`}
                                    // data-aos="fade-up"
                                    >
                                        <img
                                            src={d}
                                            style={{
                                                objectFit: 'cover'
                                            }}
                                            alt="레퍼런스 이미지"
                                        />
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Layout.Content>
            </Col>
        </Row>
    )
}