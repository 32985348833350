import { Col, Flex, Row, Space, Typography } from "antd"

export const Header = () => {
    const height = window.innerHeight
    const width = window.innerWidth

    return (
        <Row className="header">
            <Col
                span={24}
                className="parallax"
                style={{
                    height: width > 576 ? height / 2 : height,
                    backgroundPosition: 'center',
                }}
            >
                <Row>
                    <Col
                        span={24}
                        style={{
                            height: width > 576 ? height / 2 : height,
                            alignContent: 'center'
                        }}
                    >
                        <Flex justify="center" align="center" style={{ height: '100%' }}>
                            <Space size={'large'} direction="vertical" style={{ textAlign: 'center' }}>
                                <Typography.Title level={1} style={{ color: '#fff', fontSize: 52, }}>
                                    Contact
                                </Typography.Title>
                                <Typography.Title level={4} style={{ color: '#fff', fontWeight: 700, }}>
                                    광고 컨설팅 / UI, UX컨설팅 /  솔루션
                                </Typography.Title>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}