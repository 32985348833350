import { Card, Col, Flex, Layout, Row, Typography } from "antd"
import { ImageAsset } from "../../assets/images"

export const CoreValue = () => {
    const organizations = [
        {
            title: 'We, not me',
            content: '매 순간 우리는 One team임을 잊지 않고 스포츠 정신에 입각하여 미션 달성에 몰두, 몰입합니다.',
            background: ImageAsset.core_value.core1
        },
        {
            title: 'Execution over Perfection',
            subtitle: '캠페인 본부',
            content: '생각하는늑대의 구성원은 문제 해결을 위해 빠른 실행을 주저하지 않습니다.',
            background: ImageAsset.core_value.core2
        },
        {
            title: 'Feedback : aim to assist',
            subtitle: '디자인 유닛',
            content: '솔직함에 기반한 피드백은 회사와 구성원 모두를 성장으로 이끌 수 있습니다.',
            background: ImageAsset.core_value.core3
        },
        {
            title: 'Be Professional',
            subtitle: '경영지원',
            content: '프로가 되기 위해 모두가 몰입하고. 구성원이 프로가 되기 위한 모든 지원을 아끼지 않습니다.',
            background: ImageAsset.core_value.core4
        },
        {
            title: 'Break the ice',
            subtitle: '경영지원',
            content: '가볍고 유쾌한 분위기 속에서 얼음을 부수고 아이디어를 마구 발산합니다.',
            background: ImageAsset.core_value.core5
        },
    ]

    return (
        <Row className="core-value">
            <Col span={24}>
                <Flex justify="center">
                    <Typography.Title level={1} style={{ fontSize: 50, }}>
                        Core Value
                    </Typography.Title>
                </Flex>
            </Col>
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Row gutter={[12, 12]} justify={'center'}>
                        {organizations.map((d, idx: number) => {
                            return (
                                <Col key={idx} {...{ xs: 24, md: 10, lg: 7, xl: 7, xxl: 7 }}>
                                    <Card
                                        hoverable
                                        key={d.title}
                                        className={`item-container ${d.background ? '' : 'no-background'}`}
                                    >
                                        <Row className="item" align={"middle"}>
                                            <Col span={24} className="title">
                                                <Typography.Text>
                                                    {`0${idx + 1}`}
                                                </Typography.Text>
                                            </Col>
                                            <Col span={24} className="subtitle">
                                                <Typography.Text>
                                                    {d.title}
                                                </Typography.Text>
                                            </Col>
                                            <Col
                                                span={24}
                                                className="content"
                                            >
                                                <Typography.Text style={{ letterSpacing: "-0.5px" }}>
                                                    <span dangerouslySetInnerHTML={{ __html: d.content }} />
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                        {d.background && (
                                            <img
                                                src={d.background}
                                                style={{
                                                    top: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    zIndex: 1,
                                                    width: '100%',
                                                    height: '100%',
                                                    position: 'absolute',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                    opacity: 0.3,
                                                }}
                                                alt="생각하는늑대 핵심가치 이미지"
                                            />
                                        )}
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Layout.Content>
            </Col>
        </Row>
    )
}