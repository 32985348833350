import { Layout } from "antd"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { Header } from "./Header"
import { Reference } from "./Reference"

export const Works = () => {
    return (
        <MainLayout menuType={MenuType.Works}>
            <Layout.Content className="works">
                <Header />
                <Reference />
            </Layout.Content>
        </MainLayout>
    )
}